import last from 'lodash/last';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import { AutosaveTimestampOption } from '../models/settings';
import { RawBlock, RawTranscript } from '../models/transcript';
import { Tag } from '../models/tag';
import { getDisplayTimestamp } from './date-time';

export interface BlockLike
  extends Pick<
    RawBlock,
    | 'messageId'
    | 'timestamp'
    | 'speakerName'
    | 'transcript'
    | 'isPinned'
    | 'type'
    | 'isDeleted'
  > {
  tags?: Tag[] | string[];
}

export interface BlockGroup<TBlock extends BlockLike> {
  messageId: string;
  messageIds: Set<string>;
  type?: RawBlock['type'];
  timestamp: number;
  speakerName: string;
  isPinned: boolean;
  tags: TBlock['tags'];
  transcript: string;
  blocks: TBlock[];
}

export interface ExportBlockGroup<TBlock extends BlockLike>
  extends BlockGroup<TBlock> {
  displayTimestamp: string;
  tagsText: string;
}

export const escapeSpaces = (str: string): string => {
  return str.replace(/ +/gm, (match) => {
    return match.replace(/ /g, '&nbsp;');
  });
};

export const getShortSpeakerName = (speakerName = 'Participant'): string => {
  const [firstName, ...parts] = speakerName.split(' ');
  return `${firstName} ${parts.map((part) => part[0] + '.').join('')}`;
};

const getTagsText = (group: BlockGroup<BlockLike>, tags: Tag[]): string => {
  return resolveTags(group.tags ?? [], tags)
    .map((tag) => tag.icon)
    .join(' ');
};

export const transformAndCombineBlocks = <TBlock extends BlockLike>(
  sourceBlocks: TBlock[],
  timestampOption: AutosaveTimestampOption,
  tags: Tag[],
  tzOffset = 0
): ExportBlockGroup<TBlock>[] => {
  const blocks = sourceBlocks.map(
    (block): TBlock => ({
      ...block,
      transcript: incidentApr21SanitizeText(block.transcript),
      timestamp: block.timestamp - tzOffset,
    })
  );

  return combineBlocks(blocks, true).map((group): ExportBlockGroup<TBlock> => {
    const displayTimestamp = getDisplayTimestamp(
      timestampOption,
      group.timestamp,
      blocks[0].timestamp
    );

    return {
      displayTimestamp,
      tagsText: getTagsText(group, tags),
      ...group,
    };
  });
};

export const resolveTags = (tagNames: string[] | Tag[], tags: Tag[]): Tag[] => {
  return tagNames
    .map((tagName) => tags?.find((tag) => tag.name === tagName))
    .filter((tag?: Tag): tag is Tag => Boolean(tag));
};

const createBlockGroup = <TBlock extends BlockLike>(
  block: TBlock
): BlockGroup<TBlock> => ({
  messageId: block.messageId,
  messageIds: new Set([block.messageId]),
  speakerName: block.speakerName,
  blocks: [block],
  isPinned: block.isPinned ?? false,
  type: block.type,
  timestamp: block.timestamp,
  transcript: block.transcript,
  tags: block.tags ?? [],
});

const addBlockIntoGroup = <TBlock extends BlockLike>(
  block: TBlock,
  group: BlockGroup<TBlock>,
  strict = false
) => {
  group.messageIds.add(block.messageId);
  group.blocks.push(block);
  group.transcript += WORD_DELIMITER + block.transcript;

  if (!strict) {
    group.tags = uniqBy(
      [...(group.tags as string[]), ...(block.tags ?? [])],
      'icon'
    ) as TBlock['tags'];
    group.isPinned = (group.isPinned || block.isPinned) ?? false;
  }
};

export const combineBlocks = <TBlock extends BlockLike>(
  sourceBlocks: TBlock[],
  strict = true
): BlockGroup<TBlock>[] => {
  const blocks = sourceBlocks.filter((block) => !block.isDeleted);

  if (!blocks.length) return [];

  let currentGroup: BlockGroup<TBlock> = createBlockGroup(blocks[0]);
  const groups: BlockGroup<TBlock>[] = [currentGroup];

  for (let i = 1; i < blocks.length; i++) {
    const incoming = blocks[i];

    if (areMergeable(currentGroup, incoming, strict)) {
      addBlockIntoGroup(incoming, currentGroup);
    } else {
      currentGroup = createBlockGroup(incoming);
      groups.push(currentGroup);
    }
  }

  return groups;
};

function areMergeable(
  group: BlockGroup<BlockLike>,
  incoming: BlockLike,
  strict: boolean
): boolean {
  return (
    group.speakerName === incoming.speakerName && // same person (since we can have different deviceIds for the same person; this opens the window to same-named people to be confused, but that is the better bug to live with at the moment)
    group.type === incoming.type && // same source
    incoming.type !== 'screenshot' && // do not merge screenshots
    incoming.timestamp - (last(group.blocks)?.timestamp ?? 0) <
      (strict ? TIME_DISTANCE_LONG : TIME_DISTANCE) && // reasonably close; Google seems to be sending updates every ~2 seconds
    shouldMergeSentenceAware(
      group.transcript,
      incoming.transcript,
      strict ? TARGET_WORD_COUNT_LONG : TARGET_WORD_COUNT
    ) &&
    (strict ? isStrictMergeable(group, incoming) : true)
  );
}

const extractTag = (tag: Tag | string) =>
  typeof tag === 'string' ? tag : tag.icon;

function isStrictMergeable(
  group: BlockGroup<BlockLike>,
  incoming: BlockLike
): boolean {
  const groupTags = group.tags?.map(extractTag) ?? [];
  const incomingTags = incoming.tags?.map(extractTag) ?? [];

  return (
    group.isPinned === (incoming.isPinned ?? false) &&
    difference(groupTags, incomingTags).length === 0
  );
}

const TIME_DISTANCE = 5000;
const TIME_DISTANCE_LONG = 20000;

const DOT = '.';
const WORD_DELIMITER = ' ';
// average speech is 150 word per minute, targeting 15 second blocks
const TARGET_WORD_COUNT = 35;
// this is better for reading in the web app
const TARGET_WORD_COUNT_LONG = 140;

function shouldMergeSentenceAware(
  existingText: string,
  newText: string,
  targetWordCount = TARGET_WORD_COUNT
) {
  const existingTextIsFullSentence = existingText.endsWith(DOT);
  const newTextIsFullSentence = newText.endsWith(DOT);
  const combinedText = existingText + WORD_DELIMITER + newText;

  if (existingTextIsFullSentence) {
    if (newTextIsFullSentence) {
      // if both sentences are complete, then they are only merged if they fit within the target word count
      return combinedText.split(WORD_DELIMITER).length < targetWordCount;
    } else {
      // if the new sentence is incomplete, then they are merged if the first one is too short to stay separate
      return existingText.split(WORD_DELIMITER).length < targetWordCount / 2; // is existing bit too short?
    }
  } else {
    // if the previous sentence is incomplete, keep adding
    return true;
  }
}

export const isBlockHighlighted = (block: BlockLike): boolean => {
  return block.isPinned || (block.tags?.length ?? 0) > 0;
};

const resolveBlock = (blockA: RawBlock, blockB: RawBlock): RawBlock => {
  if (blockA.isDeleted) {
    return blockA;
  }

  if (blockB.isDeleted) {
    return blockB;
  }

  if (blockA.version > blockB.version) {
    return blockA;
  }

  return blockB;
};

export const resolveBlocks = (
  sourceBlocks: RawBlock[],
  updatedBlocks: RawBlock[]
): RawBlock[] => {
  const sourceHash = keyBy(sourceBlocks, 'messageId');
  const updatedHash = keyBy(updatedBlocks, 'messageId');
  const blocks: RawBlock[] = [];

  let sourceIndex = 0;
  let updatedIndex = 0;

  // merge all blocks from the updated version into the source version
  while (sourceBlocks[sourceIndex] || updatedBlocks[updatedIndex]) {
    if (!sourceBlocks[sourceIndex]) {
      blocks.push(updatedBlocks[updatedIndex]);
      updatedIndex++;
      continue;
    }

    if (!updatedBlocks[updatedIndex]) {
      blocks.push(sourceBlocks[sourceIndex]);
      sourceIndex++;
      continue;
    }

    if (
      sourceBlocks[sourceIndex].timestamp <
      updatedBlocks[updatedIndex].timestamp
    ) {
      blocks.push(sourceBlocks[sourceIndex]);
      sourceIndex++;
    } else {
      blocks.push(updatedBlocks[updatedIndex]);
      updatedIndex++;
    }
  }

  const blockIds = uniq(blocks.map((block) => block.messageId));

  return blockIds.map((messageId) => {
    const sourceBlock = sourceHash[messageId];
    const updatedBlock = updatedHash[messageId];

    if (!sourceBlock) {
      return updatedBlock;
    }

    if (!updatedBlock) {
      return sourceBlock;
    }

    return resolveBlock(sourceBlock, updatedBlock);
  });
};

export const mergeTranscripts = (
  originalTranscript: RawTranscript,
  updatedTranscript: Pick<RawTranscript, 'notes' | 'blocks' | 'updatedAt'>
): RawTranscript => {
  return Object.assign({}, originalTranscript, {
    blocks: resolveBlocks(originalTranscript.blocks, updatedTranscript.blocks),
    notes:
      (updatedTranscript.notes?.version ?? 0) >
      (originalTranscript.notes?.version ?? 0)
        ? updatedTranscript.notes
        : originalTranscript.notes,
    updatedAt: Math.max(
      originalTranscript.updatedAt,
      updatedTranscript.updatedAt
    ),
  });
};

export const incidentApr21SanitizeText = (text: string): string => {
  const removeBrokenCharacters = (chars: string[]): string => {
    const delimeterPosition = chars.findIndex((char) => char === '2');

    if (delimeterPosition === -1) {
      return chars.slice(2).join('');
    }

    return chars.slice(delimeterPosition + 2).join('');
  };

  if (text.startsWith('@spaces/')) {
    const charsArray = text.split('').slice(70);

    return removeBrokenCharacters(charsArray);
  }

  if (text.startsWith('\u0010')) {
    return removeBrokenCharacters(text.split(''));
  }

  return text;
};

export const sortBlocks = (blocks: RawBlock[]): RawBlock[] => {
  return sortBy(blocks, (block) =>
    block.isDeleted ? block.timestamp * 1000 : block.timestamp
  );
};
