import { format } from 'date-fns/format';

export function cx(...classNames: (string | undefined)[]): string {
  return classNames.filter(Boolean).join(' ');
}

export function isEmailValid(email: string): boolean {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
}

export function parseDisplayName(
  displayName?: string
): [string, string] | undefined {
  if (!displayName) {
    return;
  }
  const [firstName, ...otherNames] = displayName.split(/\s/);
  return [firstName, otherNames.join(' ')];
}

export const getNextRenewalDateString = (lastRenewal: number): string => {
  const date = new Date(lastRenewal);
  date.setMonth(date.getMonth() + 1);

  return format(date, 'd MMMM');
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function smoothScrollIntoView(
  element: HTMLElement | null,
  offsetY: number
): void {
  if (!element) return;

  let scrollTimeout: number | undefined;

  const listener = function () {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      clearTimeout(scrollTimeout);
      removeEventListener('scroll', listener);
      window.scrollBy(0, offsetY);
    }, 20) as unknown as number;
  };

  addEventListener('scroll', listener);

  setTimeout(() => {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, 0); // without the timeout, it doesn't work at all
}

export function handleScrollLock(handler: 'lock' | 'unlock'): void {
  if (handler === 'lock') {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }
  if (handler === 'unlock') {
    document.getElementsByTagName('html')[0].style.overflow = 'unset';
  }
}

/**
 *
 * @param {number} price Price
 * @returns Price string
 */
export function formatPrice(price: number): string {
  const priceString = price.toFixed(2);
  if (priceString.slice(-2) === '00') {
    return price.toFixed(0);
  } else {
    return priceString;
  }
}
