import { StorageProvider } from './storage';
import { Tag } from './tag';

export enum AutosaveTimestampOption {
  DURATION = 'kDuration',
  UTC = 'kUTC',
  NONE = 'kNone',
}

export enum AutosaveStatusOption {
  ON = 'kOn',
  OFF = 'kOff',
}

export interface AutoReplace {
  searchValue: string;
  newValue: string;
  isSystem?: boolean;
}

export interface AutoHighlight {
  searchValue: string;
  autoTag: string;
  isSystem?: boolean;
}

export enum UserSettingsPrivacyLocalField {
  PARTICIPANTS = 'PARTICIPANTS',
  TITLE = 'TITLE',
}

export interface PrivacySettings {
  localFields: UserSettingsPrivacyLocalField[];
  hideNames?: boolean;
}

export enum UserSettingsNotificationsTranscriptEmails {
  DISABLED = 'DISABLED',
  EACH = 'EACH',
}

export enum UserSettingsNotificationsTranscriptExtension {
  DISABLED = 'DISABLED',
  WHEN_MEETING_ENDED = 'WHEN_MEETING_ENDED',
}

export enum UserSettingsSharingTeam {
  DISABLED = 'DISABLED',
  AUTO = 'AUTO',
}

export enum UserSettingsNotificationsMeetingsChat {
  UNDECIDED = 'UNDECIDED',
  AUTO_TRANSCRIBING = 'AUTO_TRANSCRIBING',
  PROMPT = 'PROMPT',
  DISABLED = 'DISABLED',

  // @deprecated Use AUTO_TRANSCRIBING
  AUTO_SHARE = 'AUTO_SHARE',
}

export enum UserSettingsSharingParticipants {
  DISABLED = 'DISABLED',
  AUTO = 'AUTO',
}

export type SettingsStorageProviders = {
  Tactiq: {
    enabled: boolean;
  };
  GoogleDrive: {
    enabled: boolean;
    realTime: boolean;
  };
};

export enum UserSettingsDataMoveStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum UserSettingsSubscriptionMoveStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export type UserSettings = {
  ignoreChat?: boolean;
  highlightColor?: string;
  meetingTitleFormat?: string;
  meetingTitleDateFormat?: string;
  autoReplaces?: AutoReplace[];
  autoHighlights?: AutoHighlight[];
  // @deprecated
  autoTagQuestions?: boolean;
  autoPauseEnabled?: boolean;
  lang?: string;
  zoomwcredirect?: boolean;
  requestedDeletion?: number;
  googleDriveFolderId?: string;
  googleDriveScreenshotsFolderId?: string;
  /**
   * storage.version is used to discriminate between the users that have been migrated to the'next' version and those that haven't:
   *
   * - undefined: user has Google OAuth tokens in google_tokens collection
   * - 2: Google OAuth tokens, if any, are stored in oauth_tokens like all other OAuth integrations
   */
  storage?:
    | {
        version: undefined;
        provider: StorageProvider;
        screenshotsProvider?: StorageProvider;
      }
    | { version: 2; providers?: SettingsStorageProviders };
  hideAutoHighlightSnacks?: boolean;
  showUnsavedMeetingsInList?: boolean;
  lastRateUsModalTimestamp?: number;
  lastReasonForSignupModalTimestamp?: number;
  lastTeamShareModalTimestamp?: number;
  lastUpgradeDialogViewTimestamp?: number;
  lastCheckoutSessionCreatedTimestamp?: number;
  lastAutomaticShareDialogTimestamp?: number;
  privacy?: PrivacySettings;
  notifications?: {
    transcripts?: {
      emails?: UserSettingsNotificationsTranscriptEmails;
      extension?: UserSettingsNotificationsTranscriptExtension;
    };
    meetings?: {
      chat?: UserSettingsNotificationsMeetingsChat;
    };
  };
  sharing?: {
    team?: UserSettingsSharingTeam;
    space?: string;
    participants?: UserSettingsSharingParticipants;
  };
  tags?: Record<string, Tag>;
  // TODO: implement in backend from login, GQL, and saveMeeting, use it in API analytics
  lastClientIP?: string;
  searchEnabled?: boolean;
  gptModel?: string;
  enableLLM?: boolean;
  keepAIOutputInEnglish?: boolean;
  aiOutputLanguage?: string;
  addTactiqLinksToCalendarEvents?: boolean;
  enableEmailLinkSignIn?: boolean;
  requestedDataMove?: {
    requestedAt: number;
    sourceEmail: string;
    sourceUid: string;
    targetEmail: string;
    targetUid: string;
    status: UserSettingsDataMoveStatus;
  };
  requestedSubscriptionMove?: {
    requestedAt: number;
    sourceEmail: string;
    sourceUid: string;
    targetEmail: string;
    targetUid: string;
    status: UserSettingsSubscriptionMoveStatus;
  };
};
