export interface NewRelicBrowserAgent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  noticeError: (error: any, ...customAttributes: any) => void;
  setUserId: (value: string | null) => void;
  setCustomAttribute: (
    name: string,
    value: string | number | null,
    persist?: boolean
  ) => void;
  setErrorHandler: (callback: (err: ErrorLike) => boolean) => void;
}

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  CRITICAL = 4,
}

export interface LoggerConfig {
  logLevel: LogLevel;
  serviceName: string | undefined;
  serviceVersion?: string;
  parameters: Record<string, string> & { uid?: string; anonymousId?: string };
  useApi?: boolean;
}

export type ErrorLike = Error | (Error & Record<string, unknown>);

export class Logger {
  private config: LoggerConfig = {
    serviceName: 'tactiq-prod-au-web',
    logLevel: LogLevel.INFO,
    parameters: {
      uid: 'anonymous',
    },
  };

  private getNR() {
    if (
      !(typeof self !== 'undefined' && 'registration' in self) &&
      typeof window !== 'undefined' &&
      'newrelic' in window
    ) {
      return window.newrelic as NewRelicBrowserAgent;
    }
  }

  constructor() {
    try {
      const nr = this.getNR();
      if (nr) {
        nr.setErrorHandler((err) => {
          return (
            err.message?.includes('ResizeObserver') ||
            err.message?.includes('unable to fetch feature toggles')
          );
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug(e);
    }
  }

  configure(config: Partial<LoggerConfig>): void {
    this.config = {
      ...this.config,
      ...config,
      parameters: {
        ...this.config.parameters,
        ...config.parameters,
      },
    };
    const nr = this.getNR();
    if (nr) {
      nr.setUserId(this.config.parameters.uid ?? null);
      this.config.parameters.anonymousId &&
        nr.setCustomAttribute(
          'anonymousId',
          this.config.parameters.anonymousId
        );
    }
  }

  info(...data: unknown[]): void {
    this.write(LogLevel.INFO, ...data);
  }

  debug(...data: unknown[]): void {
    this.write(LogLevel.DEBUG, ...data);
  }

  warn(...data: unknown[]): void {
    this.write(LogLevel.WARN, ...data);
  }

  error: (
    e: ErrorLike,
    extraAttributes?: Record<string, string | number | boolean>
  ) => void = (e, extraAttributes) => {
    // eslint-disable-next-line no-console
    console.error('[Tactiq]: ', e, this.config.useApi);

    const attributes = {
      service: this.config.serviceName,
      user: {
        id: this.config.parameters.uid,
      },
      ...(extraAttributes ?? {}),
    };

    if (this.config.useApi) {
      fetch('https://log-api.newrelic.com/log/v1', {
        method: 'POST',
        headers: {
          'Api-Key': 'e6a059051ed2be070ffd6a69509de99cFFFFNRAL',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([
          {
            logs: [
              {
                message: e.message,
                level: 'error',
                attributes,
              },
            ],
          },
        ]),
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.debug(e);
      });
    } else {
      const nr = this.getNR();
      if (nr) {
        const { componentStack, ...rest } = extraAttributes ?? {};
        if (componentStack) {
          e.stack = componentStack as string;
        }
        nr.noticeError(e, rest);
      }
    }
  };

  private write(level: LogLevel, ...data: unknown[]): void {
    if (level >= this.config.logLevel) {
      // eslint-disable-next-line no-console
      console.log(`[Tactiq]: `, ...data);
    }
  }
}

const logger = new Logger();

export default logger;
