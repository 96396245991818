/**
 * ConsumerError
 * This error can be used to present error messages to consumers (see `consumerMessage` property).
 * This error can be handled by error boudary and `consumerMessage` will be displayed on the error screen.
 */
export class ConsumerError extends Error {
  consumerMessage: string;

  constructor(message: string, consumerMessage: string) {
    super(message);
    this.consumerMessage = consumerMessage;
  }
}

export class APIError extends ConsumerError {
  constructor(res: Response, errorMessage: string) {
    super(
      `API request to "${res.url}" failed with error "${res.status} ${errorMessage}"`,
      errorMessage
    );
  }
}

export class InvalidGrantError extends ConsumerError {
  constructor() {
    super(
      'Invalid grant',
      'You connection has expired. Please try to connect again or reach out to support.'
    );
  }
}
