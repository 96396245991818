import { AutosaveTimestampOption } from '../models/settings';

export const toHHMMSS = (current: number, start: number): string => {
  const diff = (current - start) / 1000;

  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff - hours * 3600) / 60);
  const seconds = Math.floor(diff - hours * 3600 - minutes * 60);

  return [...(hours ? [hours] : []), minutes, seconds]
    .map((val) => (val < 10 ? '0' + val : val))
    .join(':');
};

export const getDisplayTimestamp = (
  timestampOption: AutosaveTimestampOption,
  timestamp: number,
  firstTimestamp: number
): string => {
  switch (timestampOption) {
    case AutosaveTimestampOption.DURATION:
      return toHHMMSS(timestamp, firstTimestamp);
    case AutosaveTimestampOption.UTC:
      return new Date(timestamp).toLocaleTimeString();
    case AutosaveTimestampOption.NONE:
    default:
      return '';
  }
};
