import { logger } from '@tactiq/model';
import { APIError, InvalidGrantError } from '../error';
import { getToken } from '../firebase/auth';

/**
 * TODO: move into common package and replace usages of similar function in extension
 */
export async function fetchApiV2<T>(
  path: string,
  fetchOptions: RequestInit & {
    auth?: boolean;
    query?: Record<string, string>;
    returnRaw?: boolean;
  } = {}
): Promise<T> {
  const { auth, query, returnRaw, ...requestInit } = fetchOptions;

  const url = new URL(
    `/api/2${path}`,
    process.env.API_BASE_URL || window.location.origin
  );

  if (query) {
    Object.entries(query).forEach(([name, value]) =>
      url.searchParams.append(name, value)
    );
  }

  try {
    const res = await fetch(url.toString(), {
      ...requestInit,
      headers: {
        'Content-Type': 'application/json',
        ...(path.startsWith('/a/') || auth
          ? { Authorization: `Bearer ${await getToken(true)}` }
          : {}),
        ...(requestInit.headers || {}),
      },
    });

    if (res.ok) {
      return returnRaw ? res : res.json();
    } else {
      if (res.status === 400) {
        const json = await res.json();
        throw new APIError(res, json.error);
      }

      const text = await res.text();

      if (res.status === 403 && text.includes('Invalid grant')) {
        throw new InvalidGrantError();
      }

      throw new APIError(res, text);
    }
  } catch (err) {
    logger.error(err);
    logger.error(new Error(`fetchApiV2 failed for ${path}`));
    throw err;
  }
}
