import { createAction, createReducer } from '@reduxjs/toolkit';

export const updateInputValue = createAction<{ id: string; value: string }>(
  'UPDATE_INPUT_VALUE'
);

export const clearInputValue = createAction<string>('CLEAR_INPUT_VALUE');

export type State = Record<string, string | undefined>;

const defaultState: State = {};

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(updateInputValue, (state, { payload }) => {
      state[payload.id] = payload.value;
    })
    .addCase(clearInputValue, (state, { payload }) => {
      state[payload] = undefined;
    });
});
