import { logger } from '@tactiq/model';
import React, { ErrorInfo, PropsWithChildren } from 'react';
import { ConsumerError } from '../../helpers/error';
import { ErrorPage } from '../ErrorPage';
import { clearCache } from '../../graphql/client';

interface Props {
  ErrorComponent?: ErrorComponent;
  category?: string;
}

export type ErrorComponent = React.FC<{ message: string }>;

/**
 * ErrorBoundary is a component that catches errors in its children and displays an error page.
 */
export class ErrorBoundary extends React.Component<
  PropsWithChildren<Props>,
  { hasError: boolean; message?: string; consumerMessage?: string }
> {
  /**
   * Get derived State from error
   * @param {Error | ConsumerError} error error
   * @returns {object} state
   */
  static getDerivedStateFromError(error: Error | ConsumerError): {
    hasError: boolean;
    message: string;
    consumerMessage?: string;
  } {
    if (error instanceof ConsumerError) {
      return {
        hasError: true,
        message: error.message,
        consumerMessage: error.consumerMessage,
      };
    } else {
      return {
        hasError: true,
        message: error.message,
      };
    }
  }

  /**
   *
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.category = props.category ?? 'errorboundary';
  }

  private category: string;

  /**
   *
   * @param {Error} error error
   * @param {ErrorInfo} info info
   */
  componentDidCatch(error: Error, info: ErrorInfo): void {
    logger.error(error, {
      category: this.category,
      hash: window.location.hash,
      componentStack: info.componentStack,
    });
  }

  /**
   *
   * @returns {React.ReactNode} ReactNode
   */
  render(): React.ReactNode {
    const { ErrorComponent } = this.props;

    if (this.state.hasError) {
      logger.info(this.state);
      clearCache();
      return ErrorComponent ? (
        <ErrorComponent
          message={
            this.state.consumerMessage || 'Oops! Something went wrong :('
          }
        />
      ) : (
        <ErrorPage message={this.state.consumerMessage} />
      );
    }

    return this.props.children;
  }
}

/**
 * This is an empty component to be optionally used as the erroboundary's component, when we don't want to render anything at all (e.g. we don't care about a crashed loding icon in the button)
 * @returns {React.FC} a component that renders nothing
 */
export const Empty: React.FC = () => {
  return null;
};
