import React, { LegacyRef, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { cx } from '../../helpers/utils';
import { Empty, ErrorBoundary } from '../../app/Common/ErrorBoundary';

export type ButtonProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?:
    | 'filled'
    | 'soft'
    | 'outlined'
    | 'light'
    | 'naked'
    | 'icon'
    | 'text'
    // Matt's new Variants
    | 'secondary'
    | 'secondaryOutline';
  size?: 'text' | 'tiny' | 'small' | 'medium' | 'large' | 'icon';
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => boolean)
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<void>)
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>);
  autoFocus?: boolean;
  href?: string;
  loading?: boolean;
  color?: 'primary' | 'success' | 'error' | 'warning' | 'info';
  disabled?: boolean;
  target?: '_blank';
  download?: string;
  textSize?: 'xs';
  fullWidth?: boolean;
  buttonTeamMenu?: boolean;
  className?: string;
  hasValue?: boolean;
  id?: string;
  type?: 'button' | 'submit';
};

export type ActionButonProps = Pick<
  ButtonProps,
  'onClick' | 'loading' | 'color'
>;

export const baseClasses =
  'inline-flex items-center rounded-lg text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 whitespace-nowrap w-fit h-fit';

const buttonFullWidthClasses = 'w-full justify-center';

export const sizeClasses: Record<NonNullable<ButtonProps['size']>, string> = {
  text: 'gap-x-1 px-0 py-0',
  tiny: 'gap-x-1.5 px-1.5 py-1 text-xs',
  small: 'gap-x-1.5 px-2.5 py-1.5',
  medium: 'gap-x-1.5 px-3 py-2',
  large: 'gap-x-2 px-3 py-2.5',
  icon: 'h-4 w-4',
};

const textSizeClasses: Record<NonNullable<ButtonProps['textSize']>, string> = {
  xs: 'text-xs',
};

type VariantAndColorProps = {
  [A in NonNullable<ButtonProps['variant']>]: {
    [B in NonNullable<ButtonProps['color']>]: string;
  };
};

const lightClasses =
  'text-slate-800 font-medium bg-white shadow-[0px_1px_1px_rgba(0,0,0,0.09)] hover:bg-slate-50 ring-1 ring-inset ring-slate-300';

const iconClasses =
  'text-slate-700 rounded-full font-medium hover:bg-slate-100';

const nakedClasses = 'font-semibold bg-white hover:bg-slate-100';

const textClasses = 'text-indigo-600 font-medium hover:text-indigo-500';
const secondaryClasses = 'font-medium';
const secondaryOutlineClasses =
  'inline-flex h-8 max-w-64 cursor-pointer hover:bg-slate-50 items-center gap-1 rounded-lg ring-inset ring-1 ring-slate-200 shadow-sm px-2 py-0 text-sm text-slate-700 font-medium bg-white';

// Keep the text color as the first item, it is used in TLoading
export const colorClasses: VariantAndColorProps = {
  filled: {
    primary:
      'text-white font-medium shadow-sm bg-indigo-600 hover:bg-indigo-600/95 focus-visible:outline-indigo-600',
    success:
      'text-white font-medium shadow-sm bg-green-600 hover:bg-green-600/95 focus-visible:outline-green-600',
    error:
      'text-white font-medium shadow-sm bg-red-600 hover:bg-red-600/95 focus-visible:outline-red-600',
    warning:
      'text-white font-medium shadow-sm bg-yellow-600 hover:bg-yellow-600/95 focus-visible:outline-yellow-600',
    info: 'text-white font-medium shadow-sm bg-blue-600 hover:bg-blue-600/95 focus-visible:outline-blue-600',
  },
  soft: {
    primary:
      'text-indigo-600 font-medium shadow-sm bg-indigo-50 hover:bg-indigo-100',
    success:
      'text-green-600 font-medium shadow-sm bg-green-50 hover:bg-green-100',
    error: 'text-red-600 font-medium shadow-sm bg-red-50 hover:bg-red-100',
    warning:
      'text-yellow-600 font-medium shadow-sm bg-yellow-50 hover:bg-yellow-100',
    info: 'text-blue-600 font-medium shadow-sm bg-blue-50 hover:bg-blue-100',
  },
  outlined: {
    // 600 looks nicer than the 900, but also quite a lot brighter, how about other colors?
    primary:
      'text-indigo-600 font-semibold shadow-sm bg-white hover:bg-indigo-100 ring-1 ring-inset ring-indigo-600',

    success:
      'text-green-900 font-semibold shadow-sm bg-white hover:bg-green-100 ring-1 ring-inset ring-green-300',
    error:
      'text-red-900 font-semibold shadow-sm bg-white hover:bg-red-100 ring-1 ring-inset ring-red-300',
    warning:
      'text-yellow-900 font-semibold shadow-sm bg-white hover:bg-yellow-100 ring-1 ring-inset ring-yellow-300',
    info: 'text-blue-900 font-semibold shadow-sm bg-white hover:bg-blue-100 ring-1 ring-inset ring-blue-300',
  },
  light: {
    primary: lightClasses,
    success: lightClasses,
    error: lightClasses,
    warning: lightClasses,
    info: lightClasses,
  },
  icon: {
    primary: iconClasses,
    success: iconClasses,
    error: iconClasses,
    warning: iconClasses,
    info: iconClasses,
  },
  naked: {
    primary: nakedClasses.concat(' text-slate-700'),
    success: nakedClasses.concat(' text-green-900'),
    error: nakedClasses.concat(' text-red-600'),
    warning: nakedClasses.concat(' text-yellow-900'),
    info: nakedClasses.concat(' text-blue-900'),
  },

  text: {
    primary: textClasses,
    success: textClasses,
    error: textClasses,
    warning: textClasses,
    info: textClasses,
  },

  secondary: {
    primary: cx(secondaryClasses, 'bg-slate-100 hover:enabled:bg-slate-200/60'),
    success: cx(secondaryClasses, 'bg-green-100 hover:enabled:bg-green-200'),
    error: cx(secondaryClasses, 'bg-red-100 hover:enabled:bg-red-200'),
    warning: cx(secondaryClasses, 'bg-yellow-100 hover:enabled:bg-yellow-200'),
    info: cx(secondaryClasses, 'bg-gray-100 hover:enabled:bg-gray-200'),
  },
  secondaryOutline: {
    // these new buttons variants don't really have a color.
    primary: secondaryOutlineClasses,
    success: secondaryOutlineClasses,
    error: secondaryOutlineClasses,
    warning: secondaryOutlineClasses,
    info: secondaryOutlineClasses,
  },
};

const hasValueClasses: {
  [key in NonNullable<ButtonProps['variant']>]: string;
} = {
  filled: '',
  icon: '',
  soft: '',
  naked: '',
  secondary: '',
  outlined: '',
  light: '',
  text: '',

  secondaryOutline:
    'border-brand-100 bg-brand-25 text-brand hover:border-brand-200 hover:bg-brand-50',
};

/**
 * Button component
 * @param {unknown} param0 params
 * @param {React.ReactNode} param0.children children
 * @param {React.ReactNode} param0.startIcon startIcon
 * @param {React.ReactNode} param0.endIcon endIcon
 * @param {ButtonProps['variant']} param0.variant variant
 * @param {ButtonProps['color']} param0.color color
 * @param {ButtonProps['size']} param0.size size
 * @param {ButtonProps['onClick']} param0.onClick onClick
 * @param {ButtonProps['autoFocus']} param0.autoFocus autoFocus
 * @param {ButtonProps['href']} param0.href href
 * @param {ButtonProps['loading']} param0.loading loading
 * @param {ButtonProps['target']} param0.target target
 * @param {ButtonProps['download']} param0.download download
 * @param {ButtonProps['textSize']} param0.textSize textSize
 * @param {ButtonProps['fullWidth']} param0.fullWidth fullWidth
 * @param {ButtonProps['type']} param0.type type
 * @returns {React.FC} component
 */
export const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(function Button(
  {
    children,
    startIcon,
    variant = 'filled',
    color = 'primary',
    size = 'medium',
    disabled,
    onClick,
    autoFocus = false,
    href,
    loading,
    target,
    endIcon,
    download,
    textSize,
    fullWidth,
    className,
    hasValue,
    id,
    type = 'button',
  },
  ref: LegacyRef<HTMLButtonElement>
) {
  const navigate = useNavigate();

  return (
    <ErrorBoundary category="tButton" ErrorComponent={Empty}>
      <button
        ref={ref}
        autoFocus={autoFocus}
        type={type}
        id={id}
        className={cx(
          baseClasses,
          loading ? 'saturate-75' : '',
          sizeClasses[size],
          colorClasses[variant][color],
          hasValue ? hasValueClasses[variant] : '',
          textSize && textSizeClasses[textSize],
          fullWidth ? buttonFullWidthClasses : '',
          className
        )}
        onClick={async (e) => {
          if (loading || disabled) return;

          if (onClick) {
            const clickResult = onClick(e);
            const returnValue =
              clickResult instanceof Promise ? await clickResult : clickResult;
            if (returnValue === false) return;
          }

          if (download && href) {
            const link = document.createElement('a');
            link.href = href;
            link.download = download;
            link.click();
            URL.revokeObjectURL(link.href);
            document.removeChild(link);
          } else if (href) {
            if (target === '_blank') {
              window.open(href, '_blank');
            } else if (href.startsWith('https://')) {
              location.assign(href);
            } else {
              const targetRef = href.startsWith('/#')
                ? href.replace('/#', '')
                : href;
              navigate(targetRef);
            }
          }
        }}
        disabled={disabled ?? loading}
      >
        {loading ? (
          <TLoading
            size="small"
            color={colorClasses[variant][color].split(' ')[0]}
          />
        ) : (
          startIcon && <span>{startIcon}</span>
        )}
        {children}
        {endIcon && <span>{endIcon}</span>}
      </button>
    </ErrorBoundary>
  );
});

/**
 * Action Button
 * @param {unknown} param0 params
 * @param {React.ReactNode} param0.children children
 * @param {ActionButonProps['onClick']} param0.onClick onClick
 * @param {ActionButonProps['loading']} param0.loading loading
 * @param {ActionButonProps['color']} param0.color color
 * @returns {React.FC} component
 */
export const ActionButton: React.FC<PropsWithChildren<ActionButonProps>> = ({
  children,
  onClick,
  loading,
  color = 'primary',
}) => {
  return (
    <ErrorBoundary category="actionButton" ErrorComponent={Empty}>
      <button
        className={cx(
          baseClasses,
          loading ? 'saturate-50' : '',
          sizeClasses.small,
          colorClasses.filled[color],
          textSizeClasses.xs
        )}
        onClick={onClick}
      >
        {loading && (
          <TLoading
            size="small"
            color={colorClasses.filled[color].split(' ')[0]}
          />
        )}
        {children}
      </button>
    </ErrorBoundary>
  );
};

export const TLoading: React.FC<{
  size?: 'small' | 'medium' | 'large';
  color?: string;
}> = ({ size = 'medium', color }) => {
  const defaultClasses = 'animate-spin';
  const loadingSizeClasses: Record<NonNullable<typeof size>, string> = {
    small: 'size-4',
    medium: 'w-6 h-6',
    large: 'w-8 h-8',
  };

  return (
    <span translate="no">
      <svg
        className={cx(defaultClasses, loadingSizeClasses[size], color)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
  );
};
