const LAST_FORCE_RELOAD_AT = 'lastForceReloadAt';

const DELAYS = {
  TWO_DAYS: 1000 * 60 * 60 * 24 * 2,
  TWO_MINUTES: 1000 * 60 * 2,
};

const lastForceReloadAt = (): number => {
  return Number(localStorage.getItem(LAST_FORCE_RELOAD_AT) ?? 0);
};

const now = () => new Date().getTime();

export const forceReload = (
  delay: keyof typeof DELAYS,
  beforeReload?: () => void
): void => {
  if (now() - lastForceReloadAt() > DELAYS[delay]) {
    localStorage.setItem(LAST_FORCE_RELOAD_AT, now().toString());

    if (beforeReload) {
      beforeReload();
    }

    window.location.reload();
  }
};
