import { createAction, createReducer } from '@reduxjs/toolkit';

export const addLoader = createAction<string>('ADD_LOADER');

export const deleteLoader = createAction<string>('DELETE_LOADER');

export type State = Record<string, boolean>;

const defaultState: State = {};

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(addLoader, (state, { payload }) => {
      state[payload] = true;
    })
    .addCase(deleteLoader, (state, { payload }) => {
      state[payload] = false;
    });
});
