/* eslint-disable jsdoc/require-jsdoc */
// there are too many oneline exports for this rule to really make sense
import mixpanel, { Config } from 'mixpanel-browser';
import {
  SortBy,
  Team,
  TeamTier,
  UserTier,
  WorkflowsType,
} from '../graphql/operations';
import { baseDomain, isProduction } from './firebase/config';
import { Locale } from '@tactiq/i18n';

export enum TierPricingDialogSource {
  NAVIGATION = 'navigation',
  MEETING_TRANSCRIPT = 'meeting_transcript',
  SETTINGS = 'general_settings',
  TEAM_SETTINGS = 'team_settings',
  TEAM = 'team_page',
  BILLING = 'billing_page',
  OAUTH_CONSENT = 'oAuth_consent_page',
  AI_GENERATED = 'ai_generated',
  MEETING_ACTIONS = 'meeting_actions',
  TEAM_TRANSCRIPT = 'team_transcript',
  TEAM_MENU = 'team_menu',
  PREVIEW_TRANSCRIPT = 'preview_transcript',
  PROMO_BANNER = 'promo_banner',
  PROMO_NAVIGATION = 'promo_navigation',
  RAN_OUT_OF_CREDITS = 'ran_out_of_credits',
}

export enum KnownEvents {
  PricingUpgradeNavigationClicked = 'Pricing Upgrade Navigation Clicked',
  PricingWindowClosed = 'Pricing Window Closed',
  PricingWindowToggledInterval = 'Pricing Window Toggled subscription interval',
  PricingWindowExternalPricingLink = 'Pricing Window Clicked External Pricing Link',
  PricingWindowUpgradeToTeamPreviewInvoice = 'Pricing Window Upgrade To Team Preview Invoice',
}

export type KnownEventTypes = {
  PricingWindowUpgradeToTeamPreviewInvoice: {
    user_id: string;
    team_id: string | undefined;
    isAnnual: boolean;
    source: TierPricingDialogSource;
    userTier: UserTier;
    teamTier: TeamTier | undefined;
  };
};

// This allows setting a device ID before initialising Mixpanel so that we can track anonymous user across the extension and the web app
function ensureMixpanelInit() {
  const device_id = getMPDeviceId();

  mixpanel.init(
    isProduction()
      ? '01ed30ca1f6ac4cd0d4c3f59d96dbbb8'
      : '9a56981e0851ed25934f43c9cc43dced',
    {
      ...(device_id ? { device_id } : {}),
      ...(isProduction() ? {} : { cookie_domain: baseDomain }),
    } as Partial<Config> // we are using a custom form of the library and the official @types/... does not have the field we need
  );
}

type AnalyticsParams = Record<string, unknown> & {
  uid?: string;
  team_id?: string;
};

function trackEvent(name: string, params: AnalyticsParams = {}): boolean {
  ensureMixpanelInit();

  if (!isProduction() && typeof jest === 'undefined') {
    // eslint-disable-next-line no-console
    console.log(name, params);
  }

  mixpanel.track(name, params);
  window.dataLayer?.push({ event: `App Action: ${name}` });
  return true;
}

/**
 * @deprecated add an event function to helpers/analytics.ts instead
 */
export const trackWebEvent = trackEvent;

function track<T extends AnalyticsParams | undefined>(name: string) {
  return (params: T) => trackEvent(name, params);
}

export function trackWebPage(
  name: string,
  params: AnalyticsParams = {}
): boolean {
  ensureMixpanelInit();

  const eventName = `Viewed ${name} Page`;
  if (!isProduction() && typeof jest === 'undefined') {
    // eslint-disable-next-line no-console
    console.log(eventName, params);
  }

  mixpanel.track(eventName, { ...params, name });
  window.dataLayer?.push({ event: `App Page: ${name}` });
  return true;
}

const DEVICE_ID_KEY = 'tactiq:deviceid';

export function setMPDeviceId(deviceId: string): void {
  localStorage.setItem(DEVICE_ID_KEY, deviceId);
}

function getMPDeviceId(): string | undefined | null {
  if (
    window.location.pathname === '/auth' ||
    window.location.pathname === '/welcome'
  ) {
    return localStorage.getItem(DEVICE_ID_KEY);
  } else {
    return undefined;
  }
}

export function identifyUser(
  uid: string,
  email: string,
  displayName: string
): void {
  ensureMixpanelInit();

  mixpanel.identify(uid);
  mixpanel.people.set({ $email: email, $name: displayName });
  mixpanel.people.set_once({
    initial_page_url: window.location.href,
    initial_page_hostname: window.location.hostname,
  });

  window.dataLayer?.push({ external_id: uid });
}

export function setAnalyticsTeamId(teamId: string): void {
  mixpanel.set_group('team_id', teamId);
}

export function getValuefromURL(key: string): string | null {
  let search = location.search || location.hash;
  if (search[0] === '?') {
    search = search.slice(1);
  } else if (search[0] === '#') {
    const parts = search.split('?');
    if (parts.length > 1) {
      search = parts[1];
    } else {
      search = '';
    }
  }

  const searchParams = new URLSearchParams(search);
  return searchParams.get(key);
}

export function trackTranscriptSharingStarted(
  source:
    | 'Transcripts List - Copy Link'
    | 'Transcripts List - Generic Share'
    | 'Transcripts List - Email'
    | 'Transcript Navbar - Email'
    | 'Transcript Navbar - Copy Link'
    | 'Transcript Navbar - Generic Share'
    | 'Transcript Sidebar - Access Updates'
    | 'Transcript Sidebar - Quick Share',
  team_id: string | undefined
): void {
  trackWebEvent('Transcript Sharing Started', {
    source,
    team_id,
  });
}

/** Track when a user clicks on a file type interest button */
export function trackNewFileTypeRequested({
  userId,
  fileType,
}: {
  userId: string | undefined;
  fileType: string | undefined;
}): void {
  trackWebEvent('User clicked on file type interest button', {
    user: userId,
    fileType,
  });
}

/** User clicked transcription language override */
export function trackLanguageOverrideClicked({
  userId,
  language,
}: {
  userId: string | undefined;
  language: string;
}): void {
  trackWebEvent('User changed uploaded transcription language', {
    user: userId,
    language,
  });
}

/** Track transcript ai used */
export function trackTranscriptAIUsed(
  /** Type of prompt that was used */
  prompt_type: 'Quick Prompt' | 'AI Question' | 'AI Kit',
  /** Title of prompt used */
  prompt_title: string | 'AI Question',
  /** If AI credit was consumed by the account */
  credit_used: boolean,
  team_id?: string,
  // Language of the ai output
  lang?: string
): void {
  trackEvent('Transcript AI Used', {
    prompt_type,
    prompt_title,
    credit_used,
    team_id,
    lang,
  });
}

/** Clicked on an upgrade button */
export function trackPricingWindowUpgrade(
  user_id: string,
  team_id: string | undefined,
  source: TierPricingDialogSource,
  isAnnual: boolean,
  userTier: UserTier,
  teamTier: TeamTier | undefined,
  currency: string | undefined,
  payment_method: 'stripe' | 'paypal' | 'upgrade_request'
): void {
  const currencyUsed = currency ?? 'usd';
  trackEvent('Pricing Window Upgrade', {
    user_id,
    team_id,
    source,
    isAnnual,
    userTier,
    teamTier,
    currency: currencyUsed,
    payment_method,
  });
}

/** Viewed the pricing window */
export function trackPricingWindowViewed(
  user_id: string,
  team_id: string | undefined,
  source: TierPricingDialogSource,
  userTier: UserTier,
  teamTier: TeamTier | undefined,
  currency: string | undefined
): void {
  const currencyUsed = currency ?? 'usd';
  trackEvent('Pricing Window Viewed', {
    user_id,
    team_id,
    source,
    userTier,
    teamTier,
    currency: currencyUsed,
  });
}

//
// Spaces
//

/** Open the settings dialog */
export function trackOpenSpaceSettings(): void {
  trackEvent('Open Space Settings');
}

/** Open the members dialog */
export function trackOpenSpaceSettingsMembers(): void {
  trackEvent('Open Space Settings Members');
}

/** Close the dialog */
export function trackCloseSpaceSettings(): void {
  trackEvent('Close Space Settings');
}

/** Archive a space */
export function trackArchiveSpace(): void {
  trackEvent('Archive Space');
}

/** Leave a space */
export function trackLeaveSpace(): void {
  trackEvent('Leave Space');
}

/** User is on a page with "Your meeting list is being prepared. Please wait." alert */
export function trackListIsReindexing(user_id: string): void {
  trackEvent('Meeting List Reindexing Viewed', { user_id });
}

//
// Search
//

/** Tracks when the search popup is opened. */
export function trackSearchPopupOpen(): void {
  trackEvent('Search popup open');
}

function getPageName(): string {
  const hash = (window.location.hash || '').split('?')[0];
  return hash.split('/')[1] || 'unknown';
}

/** Tracks when the transcript list is searched. */
export function trackTranscriptListSearched({
  filter,
  sortBy,
}: {
  filter: Record<string, number | boolean>;
  sortBy: SortBy;
}): void {
  trackEvent('Searched the transcript list', {
    page: getPageName(),
    filter,
    sortBy,
  });
}

/** Tracks when the filters button is clicked. */
export function trackFiltersButtonClicked(filtersShown: boolean): void {
  trackEvent('Search filters button clicked', {
    filtersShown,
  });
}

export type SignOutTrigger =
  | 'navigation'
  | 'firebaseauth'
  | 'alertneedauth'
  | 'unauthorized';

/** Tracks user sign outs */
export function trackSignOut(trigger: SignOutTrigger): void {
  trackEvent('SignOut', { trigger });
}

export function trackUserOutOfCreditsAlert(user_id: string): void {
  trackEvent('User shown out of credits alert', {
    user_id,
  });
}

export function trackOpenUpgradeDialog(
  user_id: string,
  team_id: string | undefined,
  userTier: UserTier,
  teamTier: TeamTier | undefined
): void {
  trackEvent('User out of credits clicked upgrade', {
    user_id,
    team_id,
    userTier,
    teamTier,
  });
}

export function trackPageTranslation(lang: string, user_id: string): void {
  trackEvent('Page translation detected', { lang, user_id });
}

export function trackSupportTranslationLanguageBanner(lang: Locale): void {
  trackEvent('Supported language banner shown', { lang });
}
export function trackUpdatePreferredLanguage(
  lang: Locale,
  location: 'settingsPage' | 'languageBanner'
): void {
  trackEvent('Settings - Changed preferred language', { lang, location });
}
export function trackDismissLanguageBanner(uid: string): void {
  trackEvent('Dismissed language banner', { uid });
}
export function trackReshowLanguageBanner(uid: string): void {
  trackEvent('Reshow language banner after 30 days', { uid });
}

export function trackTeamViewUpgradeDialogViewed({
  isIndividualMember,
  isAllMembers,
}: {
  isIndividualMember: boolean;
  isAllMembers: boolean;
}): void {
  trackEvent('Team View - Upgrade dialog - viewed', {
    isIndividualMember,
    isAllMembers,
  });
}

export function trackTeamAddMembers(count: number): void {
  trackEvent('Team - Add Members', {
    count,
  });
}

export function trackTeamRemoveMember({
  teamId,
  memberId,
}: {
  teamId: string;
  memberId: string;
}): void {
  trackEvent('Team - Remove Member', {
    teamId,
    memberId,
  });
}

export function trackTeamRemoveInvitation(): void {
  trackEvent('Team - Remove Invitation');
}

export function trackTeamUpgradePendingMember(): void {
  trackEvent('Team - Tried to upgrade pending team member');
}

export function trackTeamProUpgradeRequested(teamId: string): void {
  trackEvent('Team View - Pro upgrade requested', { teamId });
}

export function trackTeamChangeMemberRoles(): void {
  trackEvent('Team - Change member roles');
}

export function trackTeamSaveAsCSVClicked(): void {
  trackEvent('Team - Save As CSV clicked');
}

export const trackUpdateSpaceOwner = (spaceIds: string[]): boolean =>
  trackEvent('Update space owner', { spaceIds });

export const trackAdminRemovedAndAssigned = (): boolean =>
  trackEvent('Admin removed themselves and assigned another admin');

export const trackAdminRemovedThemself = (): boolean =>
  trackEvent('Admin removed themselves from team');

export const trackAdminRemoveThemselfCancelledInvitations = (): boolean =>
  trackEvent('Admin removed themselves and cancelled pending invitations');

export const trackTeamMemberRemoved = (): boolean =>
  trackEvent('Team member removed from team');

export function trackReportOwnSaveAsCSVClicked(): void {
  trackEvent('Report Own Meetings - Save As CSV clicked');
}

export function trackMeetingLabelSelected(team: Team | undefined): void {
  trackEvent('Meeting label selected', { team_id: team?.id });
}

export function trackMeetingLabelCreateButtonClicked(): void {
  trackEvent('Meeting label create button clicked');
}

export function trackMeetingLabelMenuButtonClicked(): void {
  trackEvent('Meeting label menu button clicked');
}

export function trackScreenshotsInfoOpen(): void {
  trackEvent('Screenshots Info Open');
}

export function trackScreenshotsInfoClose(): void {
  trackEvent('Screenshots Info Close');
}

export function trackSetupHelperToTactiq(
  mainBlock: 'msteams' | 'googlemeet'
): void {
  trackEvent('Clicked Link - Setup Helper Continue To Tactiq', {
    main_block: mainBlock,
  });
}

export function trackSetupHelperClosed(
  mainBlock: 'msteams' | 'googlemeet'
): void {
  trackEvent('Clicked Link - Setup Helper Closed', {
    main_block: mainBlock,
  });
}

export function trackSetupHelperButton(
  platform: 'msteams' | 'googlemeet' | 'zoom' | 'webex',
  mainBlock: 'msteams' | 'googlemeet'
): void {
  let message = null;
  switch (platform) {
    case 'msteams':
      message = 'Clicked Setup Helper Button - Use with MS Teams';
      break;
    case 'googlemeet':
      message = 'Clicked Setup Helper Button - Use with Google Meet';
      break;
    case 'zoom':
      message = 'Clicked Setup Helper Button - Use with Zoom';
      break;
    case 'webex':
      message = 'Clicked Setup Helper Button - Use with Webex';
      break;
  }
  trackEvent(message, {
    main_block: mainBlock,
  });
}

export function trackEditParticipantNamesModalOpened(meetingId: string): void {
  trackEvent('Edit Participant names modal opened', {
    meeting_id: meetingId,
  });
}

export function trackEditParticipantNamesModalClosed(meetingId: string): void {
  trackEvent('Edit Participant names modal closed', {
    meeting_id: meetingId,
  });
}

export function trackMeetingParticipantNameChanged(meetingId: string): void {
  trackEvent('Meeting participant name changed sucessfully', {
    meeting_id: meetingId,
  });
}

//
// Workflow Analytics
//

// Page Views
export function viewWorkflowListPage(
  workflowsTotal: number,
  teamWorkflowsTotal: number
): void {
  trackWebPage('Workflow List Viewed', {
    workflowsTotal,
    teamWorkflowsTotal,
  });
}

export function viewWorkflowEditPage(params: WorkflowId): void {
  trackWebPage('Workflow Editor Viewed', params);
}

export function viewWorkflowHistoryPage(params: WorkflowId): void {
  trackWebPage('Workflow History Viewed', params);
}

export function viewWorkflowLibraryPage(): void {
  trackWebPage('Workflow Library Viewed');
}

export const trackWorkflowBrowsed = track<{ source: 'modal' | 'page' }>(
  'Workflow Browsed'
);

export const trackWorkflowPreview = track<
  WorkflowId & {
    source: 'modal' | 'page';
  }
>('Workflow Previewed');

// Workflow Events
type WorkflowId = { workflowId: string };

export const trackCreateWorkflow = track<undefined>('Workflow Created');

export const trackDuplicateWorkflow = track<{ duplicateFrom: string }>(
  'Workflow Duplicated'
);

export const trackSaveWorkflow = track<WorkflowId>('Workflow Saved');

export const trackArchiveWorkflow = track<WorkflowId>('Workflow Archived');

export const trackDeletedWorkflow = track<WorkflowId>('Workflow Deleted');

export const trackHistoryClicked = track<WorkflowId>(
  'Workflow History Clicked'
);

// Workflow Editor Events
export const trackWorkflowStarted = track<
  WorkflowId & { meetingId: string; source: 'meeting' | 'editor' | 'discovery' }
>('Workflow Started');

export const trackSubmitWorkflowConfirmation = track<{ isYes: boolean }>(
  'Workflow Confirmation Submited'
);

// Workflow Node Events
type Node = { workflowId: string; type: string | undefined };

export const trackAddWorkflowNode = track<Node>('Workflow Node Added');

export const trackDeleteWorkflowNode = track<Node>('Workflow Node Deleted');

export const trackRenameWorkflowNode = track<Node>('Workflow Node Renamed');

export const trackConnectWorkflowEdge = track<WorkflowId>(
  'Workflow Edge Connected'
);

export const trackDeleteWorkflowEdge = track<WorkflowId>(
  'Workflow Edge Deleted'
);

export const trackAddWorkflowTemplateVariable = track<
  Node & { template: string }
>('Workflow Template Variable Added');

// Workflow Menu Events
export const trackWorkflowMenuOpened = track<{ meetingId: string }>(
  'Workflow Menu Opened In Meeting'
);

export const trackMyMeetingClickedWorkflowMenu = track<{ meetingId: string }>(
  'Workflow Menu In Meeting - My Workflow Clicked'
);

export const trackWorkflowLibraryClickedWorkflowMenu = track<{
  meetingId: string;
}>('Workflow Menu In Meeting - Workflow Library Clicked');

export const trackWorkflowEditorOpened = track<WorkflowId>(
  'Workflow Editor Opened'
);

export const workflowListViewed = track<{
  type: WorkflowsType;
  workflowsCount: number;
}>('Workflow List Viewed');

export const trackWorkflowHistoryOpened = track<WorkflowId>(
  'Workflow History Opened'
);

export function trackWorkflowProvideFeedbackButton(): void {
  trackEvent('Workfliow Provide Feedback Button Clicked');
}
